@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap');

html,
body {
  scroll-behavior: smooth;
  margin: 0;
}

:root {
  --background: #282c34;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif !important;
}

button {
  font-family: 'Quicksand', sans-serif !important;
  font-weight: bold !important;
}

body {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: white;
}

.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
  min-height: 100vh;
}

.App-logo {
  pointer-events: none;
  max-width: 50%;
  margin: auto;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cont-plat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.plat-logo {
  background: white;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 180px;
  margin: 15px;
}

.plat-logo:hover {
  transform: scale(1.1);
}

.legal {
  color: white;
  font-size: 12px;
  margin-top: 20px;
  padding: 40px;
}

.legal .logo {
  width: 200px;
  margin-right: 10px;
  margin: auto;
  display: block;
}

.legal .back-link {
  color: white;
  text-decoration: none;
  font-size: 22px;
  display: block;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  width: 150px;
}

.terminos {
  color: white;
}

.torneos-float {
  position: fixed;
  font-size: 2em;
  font-weight: bold;
  right: 0px;
  padding: 10px;
  background: #f05829;
  border-radius: 25px 0px 0px 25px;
  box-shadow: 5px 0px 0px #ded7d7;
  cursor: pointer;
  top: 10px;
  text-decoration: none;
  color: white;
  animation: scale 0.8s infinite alternate;
}

.torneos-float:hover {
  animation: none;
}


.text-center {
  text-align: center;
}

.two-columns {
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
}

.two-columns .column {
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: unset;
  flex-direction: column;
}


/* 
torneos
*/

.next-matches-current-tournament {
  background: #f2f4f6;
  color: #1f2020;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0px 0px 10px #8d9191;
  margin-bottom: 20px;
  min-height: 677px;
}

.next-matches-current-tournament h3{
  display:block;
  text-align:center;
  margin-bottom: 30px;
  color: #ef4333;
}

.match {
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.match:last-child{
  border:none;
}

.match-teams {
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.team{
  display: flex;
  flex : 1;
  text-align: center;
  justify-content: center;
  align-items: center;

}

.vs {
  font-size: 20px;
  margin: 0px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 40px;
}

.match-time {
  font-size: 18px;
  font-weight: bold;
}

.match-location {
  font-style: italic;
}


.MuiButtonBase-root.MuiTab-root{
  color:white !important;
  max-width: 100% !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected{
  color:#f05829 !important;
}

.css-1aquho2-MuiTabs-indicator{
  background-color:#f05829 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
  background-color: #1f2020 !important;
  width: 300px !important;
  max-width: 100% !important;
  right: 0px !important;
  left: auto !important;
  top:65px !important; 
}

.MuiList-root.MuiList-padding.MuiMenu-list{
  background-color: #1f2020 !important;

}

nav.nav-pager {
  position: absolute;
  top: 50%;
  margin:0;
}

.pdf-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


ul.pager {
  position: relative;
  list-style:none;
}

ul.pager li{
  position: absolute;
  z-index: 999;
}

ul.pager li button {
  width: 50px;
  height: 50px;
  background: #f05829;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 0px 5px #353636;
}

li.previous {
  left: -55px;
}

li.next {
  right: -55px;
}


.button {
  background: #f05829 !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 0px 5px #353636;
  margin: 20px 0px;
  margin-top: 20px !important;
  transition: all 0.5s ease-in-out;
}

.button:hover {
  transform: scale(1.1);
  color:white !important;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 960px) {
  .App-logo {
    height: auto
  }

  .cont-plat {
    flex-direction: column;
  }

  .plat-logo {
    max-width: 100%;
  }

  .two-columns .column {
    width: 95%;
  }

  .two-columns {
    padding: 0px;
  }

  .match-teams {
    flex-direction: column;
  }

  .team {
    margin-bottom: 10px;
  }
  .vs {
    margin: 10px 0px;
    width: 100%;
  }

  .match-location {
  text-align: center;
  }
}

