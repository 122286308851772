h1 {
    margin: 40px 0px 20px;
    display:block;
    text-align: center;
}

h2 {
    margin: 10px 10px;
}

h3 {
    margin: 10px 20px;
}

p {
    font-size:14px;
    margin: 10px 30px;
    text-align: justify;
}

ul{
    margin:10px 60px;
}